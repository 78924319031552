import React, { useRef, useEffect } from 'react';
import confetti from 'canvas-confetti';
import { aiml, web, aws } from "../data/recruit";

const RecruitResult = () => {
    const debounceTimeout = useRef(null);

    const handleConfetti = () => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            let duration = 5 * 1000;
            let animationEnd = Date.now() + duration;
            let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            let interval = setInterval(() => {
                let timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                let particleCount = 50 * (timeLeft / duration);
                confetti(Object.assign({}, defaults, { 
                    particleCount, 
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } 
                }));
                confetti(Object.assign({}, defaults, { 
                    particleCount, 
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } 
                }));
            }, 250);
        }, 500);
    };

    useEffect(() => {
        handleConfetti();
    }, []);

    return (
        <div className='my-[120px] w-screen flex flex-col items-center'>
            <div className='md:max-w-7xl w-full'>
                <h2 className='mt-10 w-full flex justify-center xl:justify-start'>Web Development</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {web.map((data, index) => (
                        <div key={index} className="cookieCard rounded-md mx-auto sm:mx-0 hover:scale-105 transition-all duration-300 ease">
                            <p className="cookieHeading">{data.name}</p>
                            <p className="cookieDescription">
                                {data.branch} - {data.year=== 1 ? "First Year" : data.year===2 ? "Second Year" : "Third Year"}
                            </p>
                            <button className="acceptButton rounded-full" onClick={handleConfetti}>
                                Celebrate 🎉 
                            </button>
                        </div>
                    ))}
                </div>
                <h2 className='mt-10 w-full flex justify-center xl:justify-start text-center'>Artificial Intelligence and Machine Learning</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {aiml.map((data, index) => (
                        <div key={index} className="cookieCard rounded-md mx-auto sm:mx-0 hover:scale-105 transition-all duration-300 ease">
                            <p className="cookieHeading">{data.name}</p>
                            <p className="cookieDescription">
                                {data.branch} - {data.year=== 1 ? "First Year" : data.year===2 ? "Second Year" : "Third Year"}
                            </p>
                            <button className="acceptButton rounded-full" onClick={handleConfetti}>
                                Celebrate 🎉 
                            </button>
                        </div>
                    ))}
                </div>
                <h2 className='mt-10 w-full flex justify-center xl:justify-start'>Amazon Web Services(AWS)</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {aws.map((data, index) => (
                        <div key={index} className="cookieCard rounded-md mx-auto sm:mx-0 hover:scale-105 transition-all duration-300 ease">
                            <p className="cookieHeading">{data.name}</p>
                            <p className="cookieDescription">
                                {data.branch} - {data.year=== 1 ? "First Year" : data.year===2 ? "Second Year" : "Third Year"}
                            </p>
                            <button className="acceptButton rounded-full" onClick={handleConfetti}>
                                Celebrate 🎉 
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RecruitResult;
