const web = [
  { "name": "Aryan Updhyaya", "branch": "CSE-AI", "year": 2 },
  { "name": "Nandini Sharma", "branch": "CS", "year": 2 },
  { "name": "Rudra Pratap Singh", "branch": "CSE", "year": 2 },
  { "name": "Prachi Sharma", "branch": "CS", "year": 1 },
  { "name": "Anshika Chaurasiya", "branch": "ECE", "year": 1 },
  { "name": "Khushi Agarwal", "branch": "CSIT", "year": 1 },
  { "name": "Ayush Goel", "branch": "CSEAI", "year": 1 },
  { "name": "Shagun Gangwar", "branch": "CSE", "year": 1 },
  { "name": "Vikas Kumar Singh", "branch": "CSEAIML", "year": 1 },
  { "name": "Shreya Singh Rawat", "branch": "EEE", "year": 1 },
  { "name": "Akshay Pratap Singh", "branch": "CS", "year": 1 },
  { "name": "Khushi Tomar", "branch": "CSIT", "year": 1 },
  { "name": "Aditi", "branch": "CS", "year": 1 },
  { "name": "Shriti", "branch": "CSEAIML", "year": 1 },
  { "name": "Dilip Yadav", "branch": "ECE", "year": 1 },
  { "name": "Harshit Gupta", "branch": "IT", "year": 1 },
  { "name": "Rishika Batra", "branch": "CSIT", "year": 1 },
  { "name": "Prashant Tiwari", "branch": "CSEAI", "year": 1 },
  { "name": "Nitya", "branch": "ECE", "year": 1 },
  { "name": "Somya Mishra", "branch": "CSEAI", "year": 1 },
  { "name": "Vikas Kumar", "branch": "IT", "year": 1 },
  { "name": "Asif Alam", "branch": "IT", "year": 1 },
  { "name": "Aarchy Mittal", "branch": "CSEAIML", "year": 1 },
  { "name": "Yashi Bansal", "branch": "CSE", "year": 1 },
  { "name": "Prince Sharma", "branch": "CSEAI", "year": 1 },
  { "name": "Prashant Singh", "branch": "CSIT", "year": 1 },
  { "name": "Samriddh Kumar Tripathi", "branch": "ECE", "year": 1 },
  { "name": "Shiksha Agrawal", "branch": "CSEAI", "year": 1 },
  { "name": "Dhairy Goel", "branch": "CSEAI", "year": 1 },
]

const aiml = [
  { "name": "Anjali Mittal", "branch": "ECE", "year": 2 },
  { "name": "Taniya Sumbul", "branch": "ECE", "year": 2 },
  { "name": "Harsh Prajapati", "branch": "ECE", "year": 2 },
  { "name": "Piyush Dixit", "branch": "CSE(AI)", "year": 2 },
  { "name": "Abhay Gupta", "branch": "ECE", "year": 2 },
  { "name": "Manav Joshi", "branch": "CSEAI", "year": 2 },
  { "name": "Mukul Kashyap", "branch": "CSE", "year": 1 },
  { "name": "Archit Dubey", "branch": "CSIT", "year": 1 },
  { "name": "Mohammed Dawood", "branch": "MCA", "year": 1 },
  { "name": "Mradul Gupta", "branch": "CSIT", "year": 1 },
  { "name": "Harshit Singh Patel", "branch": "CSAI", "year": 1 },
  { "name": "Aditi Narang", "branch": "CSE(AI&ML)", "year": 1 },
  { "name": "Krish Sandhu", "branch": "CSEAI", "year": 1 },
  { "name": "Krishna Agarwal", "branch": "CSIT", "year": 1 },
  { "name": "Antas Kumar Dubey", "branch": "CSIT", "year": 1 },
  { "name": "Suhani Pareek", "branch": "ECE", "year": 1 },
  { "name": "Vishal Dubey", "branch": "CSE", "year": 1 },
  { "name": "Satwik Srivastav", "branch": "ECE", "year": 1 },
  { "name": "Nainsi", "branch": "CS", "year": 1 },
  { "name": "Adarsh Srivastava", "branch": "CSEAI", "year": 1 },
  { "name": "Gaurav Singh", "branch": "CSE", "year": 1 },
  { "name": "Saqiv Khan", "branch": "CS", "year": 1 },
  { "name": "Ishita", "branch": "CSE", "year": 1 },
  { "name": "Avni Singhal", "branch": "ECE", "year": 1 },
  { "name": "Dhruv Kesarwani", "branch": "CSEAI", "year": 1 },
  { "name": "Aditi Sharma", "branch": "CSE-AIML", "year": 1 },
  { "name": "Saubhagya Sharma", "branch": "CSE", "year": 1 },
  { "name": "Anupriya Saxena", "branch": "CSE", "year": 1 },
  { "name": "Rishika Nigam", "branch": "CSIT", "year": 1 },
  { "name": "Akshay Pratap Singh", "branch": "CS", "year": 1 },
  { "name": "Aditi Verma", "branch": "CSE(AI&ML)", "year": 1 },
  { "name": "Aryan Tomar", "branch": "CSE AI&ML", "year": 1 },
  { "name": "Nitin Kumar", "branch": "CS", "year": 1 },
  { "name": "Aadarsh Tripathi", "branch": "IT", "year": 1 },
  { "name": "Ojasvi Marwari", "branch": "CSE", "year": 1 },
]

const aws = [
  { "name": "Shivam sharma", "branch": "CSEAI", "year": 3 },
  { "name": "Asim Hassan", "branch": "IT", "year": 3 },
  { "name": "Sakshi Agarwal", "branch": "CSIT", "year": 2 },
  { "name": "Pravira Shukla", "branch": "CSE", "year": 2 },
  { "name": "Rachit Agarwal", "branch": "CSE", "year": 2 },
  { "name": "Raunak Kushwaha", "branch": "CSE", "year": 2 },
  { "name": "Krish Tripathi", "branch": "ECE", "year": 2 },
  { "name": "Rishi Raman Sinha", "branch": "CSE", "year": 2 },
  { "name": "Rishabh Kumar", "branch": "CS", "year": 1 },
  { "name": "Ayshman", "branch": "CSEAI", "year": 1 },
  { "name": "Ayush Rao Chaudhary", "branch": "CSE(AI&ML)", "year": 1 },
  { "name": "Mradul Gupta", "branch": "CSIT", "year": 1 },
  { "name": "Aman Paj", "branch": "CSE", "year": 1 },
  { "name": "Archit", "branch": "", "year": 1 },
  { "name": "Pawan Kumar Agarahari", "branch": "CSEAI", "year": 1 },
  { "name": "Amrit Seth", "branch": "CSE", "year": 1 },
  { "name": "Praveer Singh", "branch": "CSE", "year": 1 },
  { "name": "Archit Dubey", "branch": "CSIT", "year": 1 },
  { "name": "Priyanshi", "branch": "CSEAI", "year": 1 },
  { "name": "Mukul Kashyap", "branch": "CSE", "year": 1 },
  { "name": "Raamanjal Singh Gangwar", "branch": "CSEAI", "year": 1 },
  { "name": "Subrat Dwivedi", "branch": "CSE", "year": 1 },
  { "name": "Samvek Jain", "branch": "CSEAI", "year": 1 },
  { "name": "Rishav Raj", "branch": "ELCE", "year": 1 },
  { "name": "Dilip Yadav", "branch": "ECE", "year": 1 },
  { "name": "Neha Yadav", "branch": "CSEAI", "year": 1 },
  { "name": "Nitish Sablok", "branch": "CSEAI", "year": 1 },
  { "name": "Divya Pal", "branch": "CSEAI", "year": 1 },
  { "name": "Sugandh Garg", "branch": "IT", "year": 1 },
]

export { web, aiml, aws }