import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.png';
import { HashLink } from 'react-router-hash-link';
import { useNavigate} from "react-router-dom";
import kietLogo from "../assets/img/LOGOS.png";
import kicLogo from "../assets/img/KIC_logo.png";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <>
      <Navbar expand="lg" id="nav" className={scrolled ? "scrolled py-3" : ""}>
        <Container>
          <Navbar.Brand href="/" className="flex items-center scale-150 md:scale-100">
            <img src={logo} alt="Logo" style={{ width: "80px" }} />
            <img src={kicLogo} className="navbar_kic_logo"  alt="Kiet Logo" />
            <img src={kietLogo} className="navbar_kiet_logo" alt="Kiet Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="/#about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>About</Nav.Link>
              <Nav.Link href="/#domain" className={activeLink === 'domain' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('domain')}>Domain</Nav.Link>
              <Nav.Link href="/#event" className={activeLink === 'event' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('event')}>Events</Nav.Link>
              <Nav.Link className={activeLink === 'references' ? 'active navbar-link' : 'navbar-link'} onClick={() => navigate('/references')}>References</Nav.Link>
              <Nav.Link className={activeLink === 'team' ? 'active navbar-link' : 'navbar-link'} onClick={() => navigate("/team")}>Team</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <HashLink to='#connect'>
                <button className="vvd" style={{ padding: "10px 20px" }}><span>Let’s Connect</span></button>
              </HashLink>

            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
